
<form [formGroup]="entity">
  <div class="grid">
    <div class="col-12">
      <div class="main-title">
        Datos del crédito
        <ng-container *ngIf="data">
          <p-chip styleClass="pl-0 pr-3 ml-2">
            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="fa-solid fa-file-invoice"></i></span>
            <span class="ml-2 font-medium chip">{{data.folio}}</span>
          </p-chip>
        </ng-container>
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto Solicitado:</h3>
      <p>{{data.amount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de capital:</h3>
      <p>{{data.outstandingPrincipalAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de intéres:</h3>
      <p>{{data.outstandingInterestAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de capital de accesorios:</h3>
      <p>{{data.outstandingAccessoryPrincipalAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de intéres de accesorios:</h3>
      <p>{{data.outstandingAccessoryInterestAmount | currency}}</p>
    </div>

    <div class="col-12">
      <div class="main-title">
        Montos calculados
      </div>
    </div>
    <div class="col-12">
      <h3 class="mb-2">Monto para liquidar el crédito {{data.folio}} al momento:</h3>
      <p>{{data.amountToPay | currency}}</p>
    </div>
    <div class="col-12">
      <h3 class="mb-2">Monto restante del nuevo crédito:</h3>
      <p>{{(entity.value.amount -  data.amountToPay) | currency}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
        Configura la renovación
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="amount">Monto del nuevo crédito</label>
        <p-inputNumber formControlName="amount" inputId="amount" mode="currency" currency="MXN" locale="es-MX"> </p-inputNumber>
        <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
          <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El monto del crédito es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('amount')?.errors?.['min']">El valor minimo es {{entity.get('amount')?.errors?.['min']['min']}}.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="installmentCount">Número de letras</label>
        <p-inputNumber formControlName="installmentCount" inputId="installmentCount"> </p-inputNumber>
        <ng-container *ngIf="entity.get('installmentCount')?.invalid && (entity.get('installmentCount')?.dirty || entity.get('installmentCount')?.touched)">
          <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['required']">El número de letras es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['min']">El valor minimo de letras es {{entity.get('installmentCount')?.errors?.['min']['min']}}.</small>
        </ng-container>
      </div>
    </div>
  </div>
</form>
