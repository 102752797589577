export enum TYPE_REPORT_ENUM {
  SOD_REPORT = 'Reporte de SoD',
  CIB_REPORT = 'Reporte de migracion de PDL a CIB',
  INCIDENTS_SOD = 'Reporte de incidencias de SOD',
  INCIDENTS_LOAN = 'Reporte de incidencias de PDL',
  MIGRATION_SOD = 'Reporte de migración de SOD deprecated',
  MIGRATION_LOAN = 'Reporte de migración de PDL deprecated',
  ATR_REPORT = 'Reporte de ATR',
  ATR_PERCENT_REPORT = 'Reporte de ATR fondeador Percent',
  WORKERS_CAMPAIGNS = 'Reporte de colaboradores para campañas',
  CIRCLE_LOAN_REPORT = 'Reporte para circulo de crédito',
  APPLICATION_HISTORY_REPORT = 'Reporte de aplicaciones historica',
  ENFORCEABILITY_REPORT = 'Reporte de exigibilidad historica',
  LOAN_PYME =  'Reporte para crédito PYME',
  LOAN_CAMPAIGN = 'Reporte para campañas de crédito'
}
