import { Injectable } from '@angular/core';
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {catchError, map, Observable, of} from "rxjs";
import {ApiClass} from "@data/class/api-class";
import {
  IApiLoanList,
  IApiLoanWithInstallments,
  ILoan, ILoanOwnership,
  ILoanPopulated, ILoanRecordsPickList
} from "@modules/private/loan/data/interfaces/iloan.interface";
import {IInstallmentCalculated} from "@modules/private/loan/data/interfaces/installment.interface";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiLoanService extends ApiClass {
  constructor() {
    super();
    this.path = 'loan'
  }

  getAll(page = 1, limit = 10, filters = {}, sort = {}) {
    const response: IApiLoanList = {error: true, msg: '', data: [], totalItems: 0, status: 200, page: 1, itemsByPage: 10}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path + '/all', {page, limit, filters, sort}).pipe(
      map((r) => r as IApiLoanList),
      catchError((e) => of(response))
    )
  }

  create(data: any): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }

  createFromOmnicanal(data: any): Observable<any> {
    return this.postCollection(data, 'createFromOmnicanal').pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }

  update(data: any) {
    return this.putCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }

  getById(id: string) {
    return this.getCollection(id).pipe(map((r) => r as {error: boolean; msg: string; data: {loan: ILoanPopulated, lastPosition: IPosition, amountToPay: number, amountToPayToReferenceLoan: number}}))
  }

  getCalculatedInstallments(id: string) {
    return this.getCollection(id, environment.apiUrl, 'installment/calculate').pipe(map((r) => r as {error: boolean; msg: string; data: IInstallmentCalculated[]}))
  }

  getByUserId(id: string) {
    return this.getCollection(id, environment.apiUrl, 'loan/user').pipe(map((r) => r as {error: boolean; msg: string; data: IApiLoanWithInstallments[]}))
  }

  updateAdditionalInformation( data: any) {
    return this.patchCollection(data, environment.apiUrl, 'loan/dataLoan').pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }

  updateContractId( data: any) {
    return this.patchCollection(data, environment.apiUrl, 'loan/contract').pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }

  updateVerificationId( data: any) {
    return this.patchCollection(data, environment.apiUrl, 'loan/verification').pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }
  updateStatus ( data: any ) {
    return this.patchCollection(data, environment.apiUrl, 'loan/status').pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }

  getLoanBalanceReport(id: string) {
    return this.getCollection(id, environment.apiUrl, 'report/balanceAccount').pipe(map((r) => r as {error: boolean; msg: string; data: {url: string}}))
  }

  getByOwnership(data: any): Observable<any> {
    return this.postCollection(data, 'ownership').pipe(map((r) => r as {error: boolean; msg: string; data: {loans: ILoanRecordsPickList,loansOwnership: ILoanOwnership}}))
  }

  assignLoans(data: any): Observable<any> {
    return this.postCollection(data, '', environment.apiUrl, 'modulePermission').pipe(map((r) => r as {error: boolean; msg: string; data: ILoanOwnership}))
  }

  getAssignmentsByProductId(data: any): Observable<any> {
    return this.postCollection(data, 'byId', environment.apiUrl, 'modulePermission').pipe(map((r) => r as {error: boolean; msg: string; data: ILoanOwnership[]}))
  }
}
