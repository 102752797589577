import {Component, Input} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-loan-update-status',
  templateUrl: './loan-update-status.component.html',
  styleUrls: ['./loan-update-status.component.scss'],
  providers: [MessageService]
})
export class LoanUpdateStatusComponent {
  @Input() data!: ILoanPopulated
  acceptLoan: FormGroup
  cancelLoan: FormGroup
  isUpdating = false
  comment = ''
  statusValidationStatus = ''
  statusValidation = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Aprobado', value: 'APPROVED'},
    {label: 'Rechazado', value: 'REJECTED' }
  ]
  reasons = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Abandono de proceso', value: 'PROCESS_ABANDONMENT'},
    {label: 'Capacidad de pago', value: 'PAYMENT_CAPACITY'},
    {label: 'Proceso de aclaración en cobranza', value: 'COLLECTION_CLARIFICATION_PROCESS'},
    {label: 'Recomendación de RRHH', value: 'HR_RECOMMENDATION'},
    {label: 'Rechazo de comité', value: 'COMMITTEE_REJECTION'},
    {label: 'Referencias', value: 'REFERENCES'},
    {label: 'No cumplió con los requisitos', value: 'NOT_MEET_THE_REQUIREMENTS'},
    {label: 'Decisión del cliente', value: 'CUSTOMER_DECISION'},
    {label: 'Limite de créditos activos', value: 'ACTIVE_CREDIT_LIMIT'},
    {label: 'Despido / Renuncia', value: 'TERMINATION_RESIGNATION'},
    {label: 'Convenio Suspendido', value: 'SUSPENDED_AGREEMENT'},
    {label: 'Otro', value: 'OTHER'}
  ]
  constructor(
    private fb: FormBuilder,
    private apiLoanService: ApiLoanService,
    private messageService: MessageService,
    private router: Router,
    public authService: AuthService,
    private loaderService: LoaderService
  ) {
    this.acceptLoan = this.fb.group({
      comment: [''],
      confirmRecord: [false, [Validators.required]],
      confirmDocuments: [false, [Validators.required]],
      confirmValidation: [false, [Validators.required]],
      confirmContract: [false, [Validators.required]],
    })

    this.cancelLoan = this.fb.group({
      comment: [''],
      rejectReason: ['', [Validators.required]]
    })
  }

  updateStatus(type: string) {
    this.isUpdating = true
    console.log(type)
    this.loaderService.showSpinner()
    this.apiLoanService.updateStatus(
      type === 'APPROVED' ? {...this.acceptLoan.value, id: this.data._id, status: 'APPROVED'}
        : {...this.cancelLoan.value, id: this.data._id, status: 'DENIED'}
    ).subscribe((r) => {
      console.log(r)
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
      if (!r.error) {
        setTimeout(() => {
          this.router.navigate(['loan'])
        }, 2000)
        setTimeout(() => {
          this.router.navigate([`loan/${this.data._id}`])
        }, 3000)
      }
      this.loaderService.hideSpinner()
    })
  }
}
