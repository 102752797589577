import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MessageService} from "primeng/api";
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";

@Component({
  selector: 'app-loan-new-renovation',
  templateUrl: './loan-new-renovation.component.html',
  styleUrls: ['./loan-new-renovation.component.scss']
})
export class LoanNewRenovationComponent implements OnInit{
  @Input() entity!: FormGroup
  @Input() data!: ILoanPopulated & { amountToPay: number }
  constructor(
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.entity.controls['amount'].setValue(this.data.amountToPay + 5000)
  }
}
