import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SkeletonHeaderComponent } from './skeleton/skeleton-header/skeleton-header.component';
import { SkeletonLeftnavComponent } from './skeleton/skeleton-leftnav/skeleton-leftnav.component';
import { SkeletonFooterComponent } from './skeleton/skeleton-footer/skeleton-footer.component';
import { ListCompanyComponent } from './modules/private/company/list-company/list-company.component';
import { DetailCompanyComponent } from './modules/private/company/detail-company/detail-company.component';
import { UpdateCompanyComponent } from './modules/private/company/update-company/update-company.component';
import { CreateCompanyComponent } from './modules/private/company/create-company/create-company.component';
import { ListPayerComponent } from './modules/private/payer/list-payer/list-payer.component';
import { DetailPayerComponent } from './modules/private/payer/detail-payer/detail-payer.component';
import { CreatePayerComponent } from './modules/private/payer/create-payer/create-payer.component';
import { UpdatePayerComponent } from '@modules/private/payer/update-payer/update-payer.component';
import { ListAgreementComponent } from '@modules/private/agreement/list-agreement/list-agreement.component';
import { CreateAgreementComponent } from './modules/private/agreement/create-agreement/create-agreement.component';
import { UpdateAgreementComponent } from './modules/private/agreement/update-agreement/update-agreement.component';
import { DetailAgreementComponent } from './modules/private/agreement/detail-agreement/detail-agreement.component';
import { ListPayrollComponent } from './modules/private/payroll/list-payroll/list-payroll.component';
import { CreatePayrollComponent } from './modules/private/payroll/create-payroll/create-payroll.component';
import { UpdatePayrollComponent } from './modules/private/payroll/update-payroll/update-payroll.component';
import { DetailPayrollComponent } from './modules/private/payroll/detail-payroll/detail-payroll.component';
import { ListWorkerComponent } from './modules/private/workers/list-worker/list-worker.component';
import { ListWithdrawalComponent } from './modules/private/withdrawal/list-withdrawal/list-withdrawal.component';
import { ListLoanComponent } from './modules/private/loan/list-loan/list-loan.component';
import { ListPaymentComponent } from './modules/private/payment/list-payment/list-payment.component';
import { ListInstallmentComponent } from './modules/private/installment/list-installment/list-installment.component';
import { MassiveWorkerComponent } from './modules/private/workers/massive-worker/massive-worker.component';
import { DetailWorkerComponent } from './modules/private/workers/detail-worker/detail-worker.component';
import { WorkerListSodComponent } from './modules/private/workers/components/worker-list-sod/worker-list-sod.component';
import { WorkerListLoanComponent } from './modules/private/workers/components/worker-list-loan/worker-list-loan.component';
import { WorkerListPaymentComponent } from './modules/private/workers/components/worker-list-payment/worker-list-payment.component';
import { WorkerDetailProfileComponent } from './modules/private/workers/components/worker-detail-profile/worker-detail-profile.component';
import { WorkerOverviewComponent } from './modules/private/workers/components/worker-overview/worker-overview.component';
import { WorkerDocumentsComponent } from './modules/private/workers/components/worker-documents/worker-documents.component';
import { WorkerActivityComponent } from './modules/private/workers/components/worker-activity/worker-activity.component';
import { WorkerPulpiAdvanceComponent } from './modules/private/workers/components/worker-pulpi-advance/worker-pulpi-advance.component';
import { ListApplicationComponent } from '@modules/private/application/list-application/list-application.component';
import { CreateLoanComponent } from './modules/private/loan/create-loan/create-loan.component';
import { DetailLoanComponent } from './modules/private/loan/detail-loan/detail-loan.component';
import { WorkerLoanDetailComponent } from './modules/private/loan/components/worker-loan-detail/worker-loan-detail.component';
import { LoanRecordComponent } from './modules/private/loan/components/loan-record/loan-record.component';
import { LoanDocumentsComponent } from './modules/private/loan/components/loan-documents/loan-documents.component';
import { LoanOffersComponent } from './modules/private/loan/components/loan-offers/loan-offers.component';
import { LoanIdentityVerificationComponent } from './modules/private/loan/components/loan-identity-verification/loan-identity-verification.component';
import { LoanUpdateStatusComponent } from './modules/private/loan/components/loan-update-status/loan-update-status.component';
import { LoanHistorialComponent } from './modules/private/loan/components/loan-historial/loan-historial.component';
import { LoanResumeComponent } from './modules/private/loan/components/loan-resume/loan-resume.component';
import { LoanSingAggrementComponent } from './modules/private/loan/components/loan-sing-aggrement/loan-sing-aggrement.component';
import { MassiveApplicationComponent } from './modules/private/application/massive-application/massive-application.component';
import {IsoDateEnumPipe} from "@data/pipes/enum/iso-date-enum.pipe";
import { LoginComponent } from './modules/public/login/login.component';
import { LogoutComponent } from './modules/private/logout/logout.component';
import { PlatformSettingsComponent } from './modules/private/platform-settings/platform-settings/platform-settings.component';
import { ReportsDetailComponent } from './modules/private/reports/reports-detail/reports-detail.component';
import { SodReportComponent } from './modules/private/reports/components/sod-report/sod-report.component';
import { ReportsTableComponent } from './modules/private/reports/components/reports-table/reports-table.component';
import { ReportsPdlToCibComponent } from './modules/private/reports/components/reports-pdl-to-cib/reports-pdl-to-cib.component';
import {CoreModule} from "@app/core.module";
import { AtrReportComponent } from './modules/private/reports/components/atr-report/atr-report.component';
import { SodIncidentsReportComponent } from './modules/private/reports/components/sod-incidents-report/sod-incidents-report.component';
import { WorkersReportComponent } from './modules/private/reports/components/workers-report/workers-report.component';
import { PushingListComponent } from './modules/private/pushing/pushing-list/pushing-list.component';
import { UpdateFlowPushingComponent } from './modules/private/pushing/components/update-flow-pushing/update-flow-pushing.component';
import { PushingDetailComponent } from './modules/private/pushing/pushing-detail/pushing-detail.component';
import { PushingTimelineComponent } from './modules/private/pushing/components/pushing-timeline/pushing-timeline.component';
import { PushingRecordRequestComponent } from './modules/private/pushing/components/pushing-record-request/pushing-record-request.component';
import { PushingRecordFormComponent } from './modules/private/pushing/components/pushing-record-form/pushing-record-form.component';
import { PushingRecordDocumentComponent } from './modules/private/pushing/components/pushing-record-document/pushing-record-document.component';
import { PushingRecordIdetityComponent } from './modules/private/pushing/components/pushing-record-idetity/pushing-record-idetity.component';
import { PushingRecordSignDocumentsComponent } from './modules/private/pushing/components/pushing-record-sign-documents/pushing-record-sign-documents.component';
import { PushingRecordCancelComponent } from './modules/private/pushing/components/pushing-record-cancel/pushing-record-cancel.component';
import { PushingRecordExternalUrlComponent } from './modules/private/pushing/components/pushing-record-external-url/pushing-record-external-url.component';
import { PushingRecordManualValidationComponent } from './modules/private/pushing/components/pushing-record-manual-validation/pushing-record-manual-validation.component';
import { PushingRecordOfferComponent } from './modules/private/pushing/components/pushing-record-offer/pushing-record-offer.component';
import {SharedModule} from "@shared/shared.module";
import { WorkerUpdateComponent } from './modules/private/workers/components/worker-update/worker-update.component';
import { PushingResumeComponent } from './modules/private/pushing/components/pushing-resume/pushing-resume.component';
import { PushingNewProcessComponent } from './modules/private/pushing/components/pushing-new-process/pushing-new-process.component';
import { PushingProviderCalculatorComponent } from './modules/private/pushing/components/pushing-provider-calculator/pushing-provider-calculator.component';
import { HomeDashboardComponent } from '@modules/private/home/home-dashboard/home-dashboard.component';
import { ListAdminComponent } from './modules/private/admin/list-admin/list-admin.component';
import { AdminListComponent } from './modules/private/admin/components/admin-list/admin-list.component';
import { RoleListComponent } from './modules/private/admin/components/role-list/role-list.component';
import { RoleFormComponent } from './modules/private/admin/components/role-form/role-form.component';
import { AdminFormComponent } from './modules/private/admin/components/admin-form/admin-form.component';
import { PercentReportComponent } from '@modules/private/reports/components/percent-report/percent-report.component';
import { CircleReportComponent } from './modules/private/reports/components/circle-report/circle-report.component';
import { EnforceabilityReportComponent } from './modules/private/reports/components/enforceability-report/enforceability-report.component';
import { ApplicationReportComponent } from './modules/private/reports/components/application-report/application-report.component';
import { ListAccesoryComponent } from './modules/private/accesory/list-accesory/list-accesory.component';
import { CreateAccesoryComponent } from './modules/private/accesory/components/create-accesory/create-accesory.component';
import { CreateWriteOffComponent } from './modules/private/write-off/create-write-off/create-write-off.component';
import { ListWriteOffComponent } from '@modules/private/write-off/list-write-off/list-write-off.component';
import { LoanAssignmentComponent } from './modules/private/loan/components/loan-assignment/loan-assignment.component';
import { MassiveAssignmentComponent } from './modules/private/assignment/massive-assignment/massive-assignment.component';
import { WorkerIntegrateAccountsComponent } from './modules/private/workers/components/worker-integrate-accounts/worker-integrate-accounts.component';
import { CalendarPayrollComponent } from './modules/private/payroll/components/calendar-payroll/calendar-payroll.component';
import { LoanNewRenovationComponent } from './modules/private/loan/components/loan-new-renovation/loan-new-renovation.component';
import { LoanNewRestructuringComponent } from './modules/private/loan/components/loan-new-restructuring/loan-new-restructuring.component';

@NgModule({
  declarations: [
    AppComponent,
    SkeletonComponent,
    SkeletonHeaderComponent,
    SkeletonLeftnavComponent,
    SkeletonFooterComponent,
    ListCompanyComponent,
    DetailCompanyComponent,
    UpdateCompanyComponent,
    CreateCompanyComponent,
    ListPayerComponent,
    DetailPayerComponent,
    CreatePayerComponent,
    UpdatePayerComponent,
    ListAgreementComponent,
    CreateAgreementComponent,
    UpdateAgreementComponent,
    DetailAgreementComponent,
    ListPayrollComponent,
    CreatePayrollComponent,
    UpdatePayrollComponent,
    DetailPayrollComponent,
    ListWorkerComponent,
    ListWithdrawalComponent,
    ListLoanComponent,
    ListPaymentComponent,
    ListInstallmentComponent,
    MassiveWorkerComponent,
    DetailWorkerComponent,
    WorkerListSodComponent,
    WorkerListLoanComponent,
    WorkerListPaymentComponent,
    WorkerDetailProfileComponent,
    WorkerOverviewComponent,
    WorkerDocumentsComponent,
    WorkerActivityComponent,
    WorkerPulpiAdvanceComponent,
    ListApplicationComponent,
    CreateLoanComponent,
    DetailLoanComponent,
    WorkerLoanDetailComponent,
    LoanRecordComponent,
    LoanDocumentsComponent,
    LoanOffersComponent,
    LoanIdentityVerificationComponent,
    LoanUpdateStatusComponent,
    LoanHistorialComponent,
    LoanResumeComponent,
    LoanSingAggrementComponent,
    MassiveApplicationComponent,
    IsoDateEnumPipe,
    LoginComponent,
    LogoutComponent,
    PlatformSettingsComponent,
    ReportsDetailComponent,
    SodReportComponent,
    ReportsTableComponent,
    ReportsPdlToCibComponent,
    AtrReportComponent,
    SodIncidentsReportComponent,
    WorkersReportComponent,
    PushingListComponent,
    UpdateFlowPushingComponent,
    PushingDetailComponent,
    PushingTimelineComponent,
    PushingRecordRequestComponent,
    PushingRecordFormComponent,
    PushingRecordDocumentComponent,
    PushingRecordIdetityComponent,
    PushingRecordSignDocumentsComponent,
    PushingRecordCancelComponent,
    PushingRecordExternalUrlComponent,
    PushingRecordManualValidationComponent,
    PushingRecordOfferComponent,
    WorkerUpdateComponent,
    PushingResumeComponent,
    PushingNewProcessComponent,
    PushingProviderCalculatorComponent,
    HomeDashboardComponent,
    ListAdminComponent,
    AdminListComponent,
    RoleListComponent,
    RoleFormComponent,
    AdminFormComponent,
    PercentReportComponent,
    CircleReportComponent,
    EnforceabilityReportComponent,
    ApplicationReportComponent,
    ListAccesoryComponent,
    CreateAccesoryComponent,
    CreateWriteOffComponent,
    ListWriteOffComponent,
    LoanAssignmentComponent,
    MassiveAssignmentComponent,
    WorkerIntegrateAccountsComponent,
    CalendarPayrollComponent,
    LoanNewRenovationComponent,
    LoanNewRestructuringComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
