import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-loan-new-restructuring',
  templateUrl: './loan-new-restructuring.component.html',
  styleUrls: ['./loan-new-restructuring.component.scss']
})
export class LoanNewRestructuringComponent {
  @Input() entity!: FormGroup
  @Input() data!: ILoanPopulated & { amountToPay: number }
  constructor(
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.entity.controls['amount'].setValue(this.data.amountToPay)
  }
}
