import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {ConfirmationService} from "primeng/api";
import {LIFE_STATUS, LOAN_KIND} from "@modules/private/loan/data/enums/loan.enum";
import {environment} from "@env/environment";

@Component({
  selector: 'app-detail-loan',
  templateUrl: './detail-loan.component.html',
  styleUrls: ['./detail-loan.component.scss'],
  providers: [ConfirmationService]
})
export class DetailLoanComponent implements OnInit{
  loanId = ''
  data!: ILoanPopulated & {amountToPay: number, amountToPayToReferenceLoan: number}
  lastPosition!: IPosition
  $_loanKind = LOAN_KIND
  $_lifeStatus = LIFE_STATUS
  constructor(
    private activeRoute: ActivatedRoute,
    private apiLoanService: ApiLoanService,
    public authService: AuthService,
    private router: Router
  ) {
    this.loanId = this.activeRoute.snapshot.paramMap.get('id') as string
  }

  ngOnInit() {
    this.getData()
  }
  getData() {
    this.apiLoanService.getById(this.loanId).subscribe(r => {
      console.log('aqui')
      this.data = {...r.data.loan, amountToPay: r.data.amountToPay, amountToPayToReferenceLoan: r.data.amountToPayToReferenceLoan}
      this.lastPosition = r.data.lastPosition
    })
  }

  goLoan(id:string) {
      this.router.navigate(['/loan/'])
    setTimeout(() => {
      this.router.navigate(['/loan/'+id])
    }, 500)

  }
}
